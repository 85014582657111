import React from 'react';
import loadable from '@loadable/component';

const TextBlock = loadable(() => import('./TextBlock'));
const SingleColumnContent = loadable(() => import('./SingleColumnContent'));
const Table = loadable(() => import('./Table'));
const HtmlBlock = loadable(() => import('./HtmlBlock'));
const Embed = loadable(() => import('./Embed'));

const SliceZone = React.memo(
  ({ sliceZone, thead, body, text_before_table, text_after_table, uid }) => {
    const sliceComponents = {
      text__block: TextBlock,
      single_column_content: SingleColumnContent,
      table: Table,
      html_block: HtmlBlock,
      embed: Embed,
    };

    return sliceZone.map((slice, index) => {
      const SliceComponent = sliceComponents[slice.slice_type];
      if (SliceComponent) {
        if (slice.slice_type === 'table') {
          return (
            <Table
              thead={thead}
              body={body}
              text_before_table={text_before_table}
              text_after_table={text_after_table}
            />
          );
        }

        return <SliceComponent key={index} slice={slice} uid={uid} />;
      }
      return null;
    });
  }
);

export default SliceZone;
